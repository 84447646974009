import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-07aa896f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "honor-medal-column"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, key) => {
    return _openBlock(), _createElementBlock("div", {
      class: "honor-medal-list",
      key: key
    }, [_createElementVNode("h3", null, _toDisplayString(item.qname), 1), _createElementVNode("img", {
      src: item.imageUrl
    }, null, 8, _hoisted_2)]);
  }), 128))]);
}