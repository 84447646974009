import { Button } from 'vant';
export default {
  props: {
    scenario: {
      type: String,
      default: ''
    }
  },
  components: {
    [Button.name]: Button
  },
  setup() {
    const themeVars = {
      buttonSmallHeight: '28px',
      buttonSmallPadding: '0 11px',
      buttonWarningBackgroundColor: 'rgba(244, 152, 3, .08)',
      buttonWarningBorderColor: 'rgba(244, 152, 3, .08)',
      buttonWarningColor: '#F28B05',
      buttonSmallFontSize: '13px'
    };
    return {
      themeVars
    };
  },
  methods: {
    phone(phone) {
      location.href = 'tel:' + phone;
    }
  }
};