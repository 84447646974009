import continueEdu from '../views/continuingEducation/continuingEducation.vue';

export default [
    {
        path: '/edu',
        name: '',
        meta: {
            title: '继续教育服务',
            content: {
                keywords: "继续教育服务",
                description: "专业的团队 · 优秀的产品 · 合作共赢",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: continueEdu,
    },
];