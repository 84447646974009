import topHeader from '../../components/common/topHeader.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import aboutTab from '../../components/about/aboutTab.vue';
import companyLogo from '../../components/common/companyLogo.vue';
import { Sticky } from 'vant';
import common from "@/assets/js/common";
export default {
  components: {
    topHeader,
    bottomFoot,
    aboutTab,
    companyLogo,
    [Sticky.name]: Sticky
  },
  data() {
    return {
      scenario: 'aboutCompany',
      postData: {
        flag: 'home_qiye',
        pagesize: 100
      },
      data: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      common.http('/www/home/qiyead', this.postData, res => {
        this.data = res.list.data;
      });
    }
  }
};