import "core-js/modules/es.array.push.js";
import topHeader from '../../components/common/topHeader.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import { Sticky } from 'vant';
import common from "@/assets/js/common";
export default {
  components: {
    topHeader,
    bottomFoot,
    [Sticky.name]: Sticky
  },
  data() {
    return {
      scenario: 'newsDetail',
      postData: {
        newsid: 0
      },
      data: []
    };
  },
  created() {
    this.setQuery();
    this.getData();
  },
  methods: {
    getData() {
      common.http('/www/home/news-detail', this.postData, res => {
        this.data = res.data;
        document.title = this.data.title;

        // 增加阅读量
        common.http('/www/home/news-view', this.postData, res => {}, null, 2);
      });
    },
    setQuery() {
      let param = {};
      for (let i in this.postData) {
        let query = this.$route.query[i] === undefined ? this.$route.params[i] : this.$route.query[i];
        param[i] = query === undefined ? this.postData[i] : query;
      }
      this.postData = param;
    },
    actionTo(url) {
      this.$router.push(url);
    }
  }
};