import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {};
  },
  components: {},
  setup() {},
  methods: {
    actionTo(url) {
      this.$router.push(url);
    }
  }
};