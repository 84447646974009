import "core-js/modules/es.array.push.js";
import topHeader from '../../components/common/topHeader.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import newsTab from '../../components/news/newsTab';
import { Sticky } from 'vant';
import common from "@/assets/js/common";
export default {
  components: {
    topHeader,
    bottomFoot,
    newsTab,
    [Sticky.name]: Sticky
  },
  data() {
    return {
      scenario: 'newsList',
      postData: {
        flag: '',
        page: 0,
        pagesize: 6
      },
      data: [],
      config: {},
      loading: false,
      finished: false,
      flag: ''
    };
  },
  setup() {},
  activated() {
    if (this.$route.query.flag != this.postData.flag) {
      this.postData.flag = this.$route.query.flag;
      this.refresh();
    }
  },
  created() {
    this.setQuery();
  },
  methods: {
    getData() {
      common.http('/www/home/news-list', this.postData, res => {
        // 数据渲染
        for (let i in res.list.data) {
          this.data.push(res.list.data[i]);
        }

        // 我是有底限的
        if (res.list.data.length < 1) {
          this.finished = true;
        }

        // 刷新、加载完成
        this.loading = false;
        this.config = res.config;
        this.flag = this.postData.flag;
      });
    },
    refresh() {
      this.finished = false;
      this.data = [];
      this.postData.page = 0;
      this.loading = true;
      this.$router.push({
        path: '',
        query: this.postData
      });
      this.load();
    },
    load() {
      this.postData.page = parseInt(this.postData.page) + 1;
      this.getData();
    },
    actionTo(url) {
      this.$router.push(url);
    },
    search(e) {
      this.postData.flag = e;
      this.refresh();
    },
    setQuery() {
      let param = {};
      for (let i in this.postData) {
        let query = this.$route.query[i] === undefined ? this.$route.params[i] : this.$route.query[i];
        param[i] = query === undefined ? this.postData[i] : query;
      }
      this.postData = param;
    }
  }
};