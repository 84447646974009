/**
 * 公用函数
 */

import axios from 'axios';
import qs from 'qs';
import VueCookies from "vue-cookies";
import wx from 'weixin-js-sdk';
import wxShare from "./wxShare";

export default {
    /**
     * 设置cookie
     * @param name
     * @param value
     * @param expires
     * @param path
     * @param domain
     * @param secure
     */
    setCookie(name, value, expires = 8640000, path='/', domain='', secure=false) {
        domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';

        VueCookies.set(name, value, expires, path, domain, secure);
    },
    /**
     * 删除cookie
     * @param name
     * @param path
     * @param domain
     */
    removeCookie(name, path='/', domain='') {
        domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';
        VueCookies.remove(name, path, domain);
    },
    /**
     * 获取cookie
     *
     */
    getCookie(name) {
        return VueCookies.get(name);
    },
    /**
     * 设置localStorage【数据为对象】
     * @param name
     * @param value
     */
    setObjectLocalStorage(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
    },
    /**
     * 获取localStorage【数据为对象】
     * @param name
     * @returns {boolean}
     */
    getObjectLocalStorage(name) {
        let val = localStorage.getItem(name);
        return val ? JSON.parse(val) : false;
    },
    removeObjectLocalStorage(name) {
        localStorage.removeItem(name);
    },
    /**
     * Http请求
     * @param url 请求地址
     * @param data 请求参数
     * @param successCallback 成功回调
     * @param errorCallback 错误回调
     * @param lock 是否加锁【0否、1Post、2Get】
     * @returns {boolean}
     */
    http(url, data, successCallback, errorCallback) {

        let params = this.formatParams(data);

        axios({
            method: 'post',
            url: process.env.VUE_APP_WS + url,
            data: qs.stringify(params)
        }).then( res => {

            if (successCallback) {
                successCallback(res.data);
            }

            if (errorCallback) {
                errorCallback(res.data);
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    /**
     * 格式化请求参数
     * @param data
     * @returns {{info: string}}
     */
    formatParams(data) {
        if (!data) {
            data = {};
        }

        data.terminal = this.getTerminal();
        data.dateline = parseInt((new Date()).valueOf() / 1000);

        return data;
    },
    /**
     * 获取终端信息【是否 android\ios\微信浏览器】
     * @returns {{isWeixin: boolean, isAndroid: boolean, isiOS: boolean}}
     */
    getTerminal() {

        if (this.inWechat() !== false) {
            return 3;
        }

        return process.env.VUE_APP_TERMINAL;
    },
    inWechat() {
        let ua = navigator.userAgent.toLowerCase();

        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        }

        return false;
    },
    /**
     * 获取微信分享配置信息
     * @param url
     */
    getWechatShareConfig(url) {
        if (url.indexOf('http') === -1) {
            url = process.env.VUE_APP_M + url;
        }

        this.http('/m/wechat/share', {url: url}, (res) => {
            wxShare.wxRegister({
                appId: res.data.appId,
                timestamp: res.data.timestamp,
                nonceStr: res.data.nonceStr,
                signature: res.data.signature
            });

            let param = {
                title: '爱沃客 - 让工作更简单！',
                desc: '安徽爱沃客信息科技有限公司是以⼈⼒资源服务、职业教育服务、继续教育服务为主的科技型⼈⼒资源及⼈才发展服务公司。',
                imgUrl: 'http://m.iwowke.com/image/logo.png',
                url: url,
            };
            wxShare.shareL(param);

            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            wx.miniProgram.getEnv((res)=>{
                if (res.miniprogram) {
                    let obj = {
                        title: param.title,
                        desc: param.desc,
                    }
                    wx.miniProgram.postMessage({
                        data: obj
                    });
                }
            })
        });
    },
};
