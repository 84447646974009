import {createRouter, createWebHistory} from 'vue-router';
import common from '@/assets/js/common';

import home from './home';
import vocational from './vocational';
import continueEdu from './continueEdu';
import xushuichi from './xushuichi';
import about from './about';
import news from './news';
import contact from './contact';

const routes = [
    ...home,
    ...vocational,
    ...continueEdu,
    ...xushuichi,
    ...about,
    ...news,
    ...contact,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {

    // 保持缓存页点击位置
    if (from.meta.keepAlive) {
        const $content = document.querySelector('#content');
        const scrollTop = $content ? $content.scrollTop : 0;
        from.meta.scrollTop = scrollTop;
    }

    //console.log(to);
    // console.log(from);

    // 设置标题
    document.title = to.meta.title;

    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }

    // 微信
    if (common.inWechat() !== false) {
        // 分享配置
        common.getWechatShareConfig(to.fullPath);
    }

});

router.afterEach((to, from) => {
    // 新打开页面，跳转至顶部
    window.scrollTo(0, 0);
});

export default router
