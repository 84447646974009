import "core-js/modules/es.array.push.js";
import topHeader from '../../components/common/topHeader.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import aboutTab from '../../components/about/aboutTab.vue';
import { Sticky } from 'vant';
import common from "@/assets/js/common";
export default {
  components: {
    topHeader,
    bottomFoot,
    aboutTab,
    [Sticky.name]: Sticky
  },
  data() {
    return {
      scenario: 'aboutWenhua',
      postData: {
        flag: 'wenhua',
        page: 0,
        pagesize: 9
      },
      data: [],
      loading: false,
      finished: false
    };
  },
  created() {},
  methods: {
    getData() {
      common.http('/www/home/news-list', this.postData, res => {
        // 数据渲染
        for (let i in res.list.data) {
          this.data.push(res.list.data[i]);
        }

        // 我是有底限的
        if (res.list.data.length < 1) {
          this.finished = true;
        }

        // 刷新、加载完成
        this.loading = false;
      });
    },
    refresh() {
      this.finished = false;
      this.data = [];
      this.postData.page = 0;
      this.loading = true;
      this.load();
    },
    load() {
      this.postData.page = this.postData.page + 1;
      this.getData();
    },
    actionTo(url) {
      this.$router.push(url);
    }
  }
};