import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-38d1a9af"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "about-tab"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_tabs = _resolveComponent("van-tabs");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_config_provider, {
    "theme-vars": $setup.themeVars
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabs, {
      active: $data.activeFlag,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => $data.activeFlag = $event),
      onClickTab: $options.onClickTab,
      color: "#16A0F0",
      "title-active-color": "#16A0F0",
      "swipe-threshold": "3",
      "line-width": "27px",
      "line-height": "2px"
    }, {
      default: _withCtx(() => [_createVNode(_component_van_tab, {
        title: "全部动态",
        name: ""
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.config.class, (item, key) => {
        return _openBlock(), _createBlock(_component_van_tab, {
          key: key,
          title: item.ncname,
          name: item.flag
        }, null, 8, ["title", "name"]);
      }), 128))]),
      _: 1
    }, 8, ["active", "onClickTab"])]),
    _: 1
  }, 8, ["theme-vars"])]);
}