import about from '../views/about/companyProfile.vue';
import honor from '../views/about/quaHonor.vue';
import partner from '../views/about/coopPartner';
import welfare from '../views/about/publicWelfare.vue';
import culture from '../views/about/companyCulture.vue';

export default [
    {
        path: '/about',
        name: '',
        meta: {
            title: '爱沃客',
            content: {
                keywords: "爱沃客",
                description: "爱沃客",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: about,
    },
    {
        path: '/about/honor',
        name: '',
        meta: {
            title: '爱沃客',
            content: {
                keywords: "爱沃客",
                description: "爱沃客",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: honor,
    },
    {
        path: '/about/company',
        name: '',
        meta: {
            title: '爱沃客',
            content: {
                keywords: "爱沃客",
                description: "爱沃客",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: partner,
    },
    {
        path: '/about/gongyi',
        name: '',
        meta: {
            title: '爱沃客',
            content: {
                keywords: "爱沃客",
                description: "爱沃客",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: welfare,
    },
    {
        path: '/about/wenhua',
        name: '',
        meta: {
            title: '爱沃客',
            content: {
                keywords: "爱沃客",
                description: "爱沃客",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: culture,
    },
];