import "core-js/modules/es.array.push.js";
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUs.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import { Sticky, Swipe, SwipeItem } from 'vant';
import common from "@/assets/js/common";
export default {
  components: {
    topHeader,
    contactUs,
    bottomFoot,
    [Sticky.name]: Sticky,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  setup() {
    const themeVars = {
      buttonNormalPadding: '0 25px',
      swipeIndicatorMargin: '0',
      swipeIndicatorSize: '8px',
      swipeIndicatorInactiveBackgroundColor: '#e0e0e0'
    };
    return {
      themeVars
    };
  },
  data() {
    return {
      scenario: 'serviceXsc',
      postData: {},
      dataAdQiye: [],
      dataAdSchool: [],
      dataNewsJinggong: [],
      dataNewsShare: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      common.http('/www/home/xsc', this.postData, res => {
        this.dataAdQiye = res.list_ad_qiye.data;
        this.dataAdSchool = res.list_ad_school.data;
        this.dataNewsJinggong = res.list_news_jinggong.data;
        this.dataNewsShare = res.list_news_share.data;
      });
    },
    actionTo(url) {
      this.$router.push(url);
    }
  }
};