import newsList from '../views/news/newsList.vue';
import newsDetail from '../views/news/newsDetail.vue';

export default [
    {
        path: '/news',
        name: '',
        meta: {
            title: '人力资源服务',
            content: {
                keywords: "人力资源服务",
                description: "⼗佳⼈⼒资源供给服务单位",
            },
            requireLogin: false,
            keepAlive: true,
        },
        component: newsList,
    },
    {
        path: '/news/:newsid(\\d+)',
        name: '',
        meta: {
            title: '人力资源服务',
            content: {
                keywords: "人力资源服务",
                description: "⼗佳⼈⼒资源供给服务单位",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: newsDetail,
    },
];