import { Tab, Tabs } from 'vant';
export default {
  props: {
    config: {
      type: Object,
      default: {}
    },
    flag: {
      type: String,
      default: ''
    }
  },
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  watch: {
    flag(val) {
      this.$nextTick(() => {
        this.activeFlag = val;
      });
    }
  },
  data() {
    return {
      activeFlag: this.flag
    };
  },
  setup() {
    const themeVars = {
      tabFontSize: '15px',
      tabsLineHeight: '49px',
      paddingSm: '16px'
    };
    return {
      themeVars
    };
  },
  methods: {
    onClickTab(e) {
      this.$emit("search", e.name);
    }
  }
};