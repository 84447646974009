import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    list: {
      type: [Object, Array],
      default: []
    }
  },
  data() {
    return {};
  },
  setup() {}
});