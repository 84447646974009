import topHeader from '@/components/common/topHeader.vue';
import companyLogo from '@/components/common/companyLogo.vue';
import contactUs from '@/components/common/contactUs.vue';
import bottomFoot from '@/components/common/bottomFoot.vue';
import { Sticky, Space } from 'vant';
import common from '@/assets/js/common';
export default {
  components: {
    topHeader,
    companyLogo,
    contactUs,
    bottomFoot,
    [Sticky.name]: Sticky,
    [Space.name]: Space
  },
  setup() {},
  data() {
    return {
      scenario: 'serviceHR',
      postData: {
        flag: 'home_qiye',
        pagesize: 9
      },
      data: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      common.http('/www/home/qiyead', this.postData, res => {
        this.data = res.list.data;
      });
    }
  }
};