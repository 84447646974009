import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUs.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import { Sticky } from 'vant';
export default {
  components: {
    topHeader,
    contactUs,
    bottomFoot,
    [Sticky.name]: Sticky
  },
  setup() {
    const themeVars = {
      buttonNormalPadding: '0 25px',
      buttonDefaultHeight: '33px'
    };
    return {
      themeVars
    };
  },
  data() {
    return {
      scenario: 'contactUs',
      data: []
    };
  }
};