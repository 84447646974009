import Home from '../views/home/HRhome.vue';

export default [
    {
        path: '/',
        name: '',
        meta: {
            title: '人力资源服务',
            content: {
                keywords: "人力资源服务",
                description: "⼗佳⼈⼒资源供给服务单位",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: Home,
    },
];