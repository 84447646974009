import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5f4d29ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "news-detail-column"
};
const _hoisted_3 = {
  class: "news-detail"
};
const _hoisted_4 = {
  class: "news-source flex algin-items"
};
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top_header = _resolveComponent("top-header");
  const _component_van_sticky = _resolveComponent("van-sticky");
  const _component_bottom_foot = _resolveComponent("bottom-foot");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_sticky, null, {
    default: _withCtx(() => [_createVNode(_component_top_header, {
      scenario: $data.scenario
    }, null, 8, ["scenario"])]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h1", null, _toDisplayString($data.data.title), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("a", {
    href: "javascript:;",
    onClick: _cache[0] || (_cache[0] = $event => $options.actionTo('/news/?flag=' + $data.data.flag))
  }, _toDisplayString($data.data.ncname), 1), _createElementVNode("p", null, [_createElementVNode("span", null, _toDisplayString($data.data.addtime_str), 1), _createElementVNode("span", null, "阅读 " + _toDisplayString($data.data.viewnum), 1)])]), _createElementVNode("div", {
    class: "news-content",
    innerHTML: $data.data.content
  }, null, 8, _hoisted_5)])]), _createVNode(_component_bottom_foot)]);
}