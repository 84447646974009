import "core-js/modules/es.array.push.js";
import { Tab, Tabs } from 'vant';
import { ref } from 'vue';
export default {
  props: {
    scenario: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  setup(props) {
    const themeVars = {
      tabFontSize: '15px',
      tabsLineHeight: '49px',
      paddingSm: '16px'
    };
    const active = ref(props.scenario);
    return {
      active,
      themeVars
    };
  },
  methods: {
    onClickTab(e) {
      let url = '';
      switch (e) {
        case 'aboutHonor':
          url = '/about/honor';
          break;
        case 'aboutWenhua':
          url = '/about/wenhua';
          break;
        case 'aboutGongyi':
          url = '/about/gongyi';
          break;
        case 'aboutCompany':
          url = '/about/company';
          break;
        default:
          url = '/about';
          break;
      }
      this.$router.push(url);
    }
  }
};