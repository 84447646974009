import xushuichi from '../views/HRxsc/xuShuiChi.vue';

export default [
    {
        path: '/xsc',
        name: '',
        meta: {
            title: '人力资源蓄水池',
            content: {
                keywords: "人力资源蓄水池",
                description: "安徽省首个政府主导，爱沃客公司运营的专项精工技能人才培养项目",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: xushuichi,
    },
];