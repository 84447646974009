import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import { Popup, Icon } from 'vant';
export default {
  props: {
    scenario: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      active: ''
    };
  },
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon
  },
  setup(props) {
    let checked = {};
    checked[props.scenario] = 'checked';
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    const showAbout = ref(false);
    const showA = () => {
      showAbout.value = !showAbout.value;
    };
    if (props.scenario == 'aboutIntro' || props.scenario == 'aboutHonor' || props.scenario == 'aboutWenhua' || props.scenario == 'aboutGongyi' || props.scenario == 'aboutCompany') {
      checked['about'] = 'checked';
      showAbout.value = true;
    } else {
      checked['about'] = '';
      showAbout.value = false;
    }
    return {
      show,
      showPopup,
      showAbout,
      showA,
      checked
    };
  },
  methods: {
    vueOnScroll() {
      var prev = window.pageYOffset;
      window.addEventListener("scroll", () => {
        var curr = window.pageYOffset;
        if (prev > curr) {
          this.active = 'white-bg';
        } else {
          this.active = 'white-bg';
        }
        if (curr === 0) {
          this.active = '';
        }
        prev = curr;
      });
    },
    actionTo(url) {
      this.$router.push(url);
      this.show = false;
    }
  },
  mounted() {
    // run the function when the component's mount
    this.vueOnScroll();
  }
};