import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3eaa279c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "about-tab"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_tabs = _resolveComponent("van-tabs");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_config_provider, {
    "theme-vars": $setup.themeVars
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabs, {
      active: $setup.active,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => $setup.active = $event),
      onChange: $options.onClickTab,
      color: "#16A0F0",
      "title-active-color": "#16A0F0",
      "swipe-threshold": "3",
      "line-width": "27px",
      "line-height": "2px",
      animated: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_van_tab, {
        title: "企业介绍",
        name: "aboutIntro"
      }), _createVNode(_component_van_tab, {
        title: "资质荣誉",
        name: "aboutHonor"
      }), _createVNode(_component_van_tab, {
        title: "企业文化",
        name: "aboutWenhua"
      }), _createVNode(_component_van_tab, {
        title: "沃公益",
        name: "aboutGongyi"
      }), _createVNode(_component_van_tab, {
        title: "合作伙伴",
        name: "aboutCompany"
      })]),
      _: 1
    }, 8, ["active", "onChange"])]),
    _: 1
  }, 8, ["theme-vars"])]);
}