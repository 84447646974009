import "core-js/modules/es.array.push.js";
import topHeader from '../../components/common/topHeader.vue';
import contactUs from '../../components/common/contactUs.vue';
import bottomFoot from '../../components/common/bottomFoot.vue';
import { Sticky } from 'vant';
import common from "@/assets/js/common";
export default {
  components: {
    topHeader,
    contactUs,
    bottomFoot,
    [Sticky.name]: Sticky
  },
  setup() {
    const themeVars = {
      buttonNormalPadding: '0 25px'
    };
    return {
      themeVars
    };
  },
  data() {
    return {
      scenario: 'serviceVocation',
      postData: {
        flag: 'vocation_school',
        pagesize: 12
      },
      dataAd: [],
      dataNews: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      common.http('/www/home/vocation', this.postData, res => {
        this.dataAd = res.list_ad.data;
        this.dataNews = res.list_news.data;
      });
    },
    actionTo(url) {
      this.$router.push(url);
    }
  }
};