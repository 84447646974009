import vocational from '../views/vocationalEducation/vocationalEducation.vue';

export default [
    {
        path: '/zhiye',
        name: '',
        meta: {
            title: '职业教育服务',
            content: {
                keywords: "职业教育服务",
                description: "外引“⽔”内调剂、探索⾼质量就业",
            },
            requireLogin: false,
            keepAlive: false,
        },
        component: vocational,
    },
];